<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Forms List (Admin View)</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip v-if="isEmployee" top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon to="/system/drive-folders">
            <v-icon>fab fa-google-drive</v-icon>
          </v-btn>
        </template>
        <span>View {{ user.roles.includes('Technology Services') ? 'all' : 'your' }} Google Drive upload permissions</span>
      </v-tooltip>
      <v-btn icon @click="showFilters = !showFilters">
        <v-icon>{{ filtersActive ? 'fas' : 'fal' }} fa-filter</v-icon>
      </v-btn>
      <v-dialog v-model="newForm" width="400">
        <template v-slot:activator="{ on:dialogOn }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn v-if="isEmployee" icon v-on="{ ...dialogOn, ...tooltipOn }">
                <v-icon>fal fa-plus</v-icon>
              </v-btn>
            </template>
            <span>New Form</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-card-title>Create New Form</v-card-title>
          <v-card-text>
            <v-form v-model="formValid" ref="form">
              <v-text-field v-model="name" label="Name of Form (must be unique)" :error-messages="nameErrors" outlined></v-text-field>
              <v-text-field v-model="shortName" label="Short Name" hint="Must be unique; only letters, numbers, and hyphens." :error-messages="shortNameErrors" persistent-hint outlined>
                <template v-slot:append-outer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                    </template>
                    <div style="max-width:300px">The short name is what will be in the link to the form.If you enter a short name of simple-form, then the link would be: https://portal.covenant.edu/forms/simple-form</div>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-combobox v-model="group" label="Form Group" :items="groups" :error-messages="formGroupErrors" outlined>
                <template v-slot:append-outer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                    </template>
                    <div style="max-width:300px">Select a group from the list or type in a group name to create a new group</div>
                  </v-tooltip>
                </template>
              </v-combobox>
              <v-autocomplete v-model="copyForm" :search-input.sync="copyFormSearch" :items="copyFormItems" label="Copy content from form" outlined>
                <template v-slot:append-outer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="info">fas fa-question-circle</v-icon>
                    </template>
                    <div style="max-width:300px">This will copy the content of the selected form (all of the form inputs, any info text, and the confirmation text), but not all of the submission settings.</div>
                  </v-tooltip>
                </template>
              </v-autocomplete>
              <v-checkbox v-model="loginRequired" label="Login is required to access" hide-details></v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="nameErrors.length > 0 || shortNameErrors.length > 0 || formGroupErrors.length > 0" color="success" @click="makeNewForm()">Create Form</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="newForm = false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-card-text v-if="showFilters">
      <v-row>
        <v-col>
          <v-text-field v-model="nameFilter" label="Name Filter" outlined hide-details></v-text-field>
        </v-col>
        <v-col>
          <v-autocomplete v-model="groupFilter" :items="groups" label="Group Filter" multiple chips deletable-chips small-chips outlined hide-details></v-autocomplete>
        </v-col>
        <v-col>
          <v-select v-model="statusFilter" :items="statusOptions" label="Status Filter" outlined hide-details></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :items="forms" :headers="headers" :options="pagination" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 25] }">
      <template v-slot:no-data>
        <v-alert v-if="isLoading" type="info" outlined>Loading forms...</v-alert>
        <v-alert v-else-if="filtersActive" type="info" outlined>There are no forms which match your current filter. Please change options and try again.</v-alert>
        <v-alert v-else type="error">
          You do not have access to any forms.
          <span v-if="isEmployee">You can create a new form by clicking on the + icon above or contact the person in charge of the form you are looking for and ask them to add you to the form.</span>
          <span v-else>Only employees can create new forms. If you are expecting access to a form, contact the person in charge of the form you are looking for and ask them to add you to the form.</span>
        </v-alert>
      </template>
      <template v-slot:item.name="{ item }">
        <router-link :to="'/forms/admin/' + item._id">{{ item.name }}</router-link>
      </template>
      <template v-slot:item.latestRevision="{ item }">
        {{ item.latestRevision ? (item.revDiff === 0 ? 'Today' : (item.revDiff > 20 ? stringFormatDate(item.latestRevision) : item.revDiff + ' Days Ago')) : '' }}
      </template>
      <template v-slot:item.latestSubmission="{ item }">
        {{ item.latestSubmission ? (item.subDiff === 0 ? 'Today' : (item.subDiff > 20 ? stringFormatDate(item.latestSubmission) : item.subDiff + ' Days Ago')) : '' }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isEmployee = computed(() => user.value.roles.includes('Employee'))
    const newForm = ref(false)
    const formValid = ref(true)
    const form = ref(null)
    const name = ref('')
    const shortName = ref('')
    const group = ref('')
    const formGroupErrors = ref([])
    const loginRequired = ref('')
    const nameErrors = ref([])
    const shortNameErrors = ref([])
    const groups = ref([])
    const statusOptions = ref([
      { text: 'All Current Forms', value: '' },
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' },
      { text: 'Retired', value: 'Retired' }
    ])
    const service = root.$feathers.service('forms/base')
    watch(name, (val) => {
      if (val.length < 10) {
        nameErrors.value = ['Too short (10 character minimum)']
      } else {
        // Check to see if this value is unique
        service.find({ query: { name: val, $limit: 0 } }).then(({ total }) => {
          if (name.value === val) {
            // See if there is at least one match, and that the matched name still matches the entered name (i.e. if the server was slower to respond and they have entered a longer name)
            if (total > 0) {
              nameErrors.value = ['Name is already in use']
            } else {
              nameErrors.value = []
            }
          }
        })
      }
    })
    watch(shortName, (val) => {
      let regex = /^[a-zA-Z0-9-]*$/
      if (val.length < 5) {
        shortNameErrors.value = ['Too short (5 character minimum)']
      } else if (!regex.test(val)) {
        shortNameErrors.value = ['Invalid character(s). Only letters, numbers, and dashes.']
      } else if (val.length > 30) {
        shortNameErrors.value = ['Too long (30 character maximum)']
      } else {
        // Check to see if this value is unique
        service.find({ query: { shortName: val, $limit: 0 } }).then(({ total }) => {
          if (shortName.value === val) {
            // See if there is at least one match, and that the matched name still matches the entered name (i.e. if the server was slower to respond and they have entered a longer name)
            if (total > 0) {
              shortNameErrors.value = ['Short name is already in use']
            } else {
              shortNameErrors.value = []
            }
          }
        })
      }
    })
    watch(group, (val) => {
      formGroupErrors.value = []
      if (typeof (val) === 'string') {
        if (val.length < 10) {
          formGroupErrors.value.push('Too short (10 character minimum)')
        }
      }
    })

    const copyForm = ref('')
    const copyFormSearch = ref('')
    const copyFormItems = ref([])
    watch(copyFormSearch, (val) => {
      if (typeof (val) === 'string') {
        root.$feathers.service('forms/base').find({ query: { name: { $regex: val, $options: 'i' }, $limit: 10 } }).then(({ data }) => {
          copyFormItems.value = []
          data.forEach(({ name, _id }) => {
            copyFormItems.value.push({ text: name, value: _id })
          })
        })
      }
    })
    async function makeNewForm () {
      if (form.value.validate()) {
        service.create({
          name: name.value,
          shortName: shortName.value,
          group: typeof (group.value) === 'object' ? group.value.value : group.value,
          access: {
            loginRequired: loginRequired.value || false,
            roles: [],
            users: [{
              pidm: user.value.pidm,
              name: user.value.name,
              email: user.value.email,
              role: 'Admin',
              notify: 'Yes'
            }]
          }
        }).then(({ _id }) => {
          if (copyForm.value !== '') {
            // Build a revision that is copied from the current revision of the given form
            root.$feathers.service('forms/base').get(copyForm.value).then((data) => {
              let revId = null
              if (data.revisions.current != null && data.revisions.current !== '') {
                revId = data.revisions.current
              } else if (data.revisions.editing != null && data.revisions.editing !== '') {
                revId = data.revisions.editing
              }
              if (revId != null) {
                root.$feathers.service('forms/revision').get(revId).then((data) => {
                  let revision = {
                    form: _id,
                    version: 1,
                    active: false,
                    sectionType: data.sectionType,
                    showTitle: data.showTitle,
                    infoCard: data.infoCard,
                    infoCardText: data.infoCardText,
                    sections: data.sections,
                    confText: data.confText
                  }
                  root.$feathers.service('forms/revision').create(revision).then((data) => {
                    root.$feathers.service('forms/base').patch(_id, { revisions: { editing: data._id } }).then(() => {
                      root.$router.push('/forms/admin/' + _id)
                    })
                  })
                })
              }
            })
          } else {
            root.$router.push('/forms/admin/' + _id)
          }
        })
      }
    }

    const forms = ref([])
    const formCount = ref(0)
    const headers = ref([
      { text: 'Name', value: 'name' },
      { text: 'Short Name', value: 'shortName' },
      { text: 'Group', value: 'group' },
      { text: 'Status', value: 'status' },
      { text: 'Latest Revision', value: 'latestRevision' },
      { text: 'Latest Submission', value: 'latestSubmission' },
      { text: 'Submission Count', value: 'submissionCount' }
    ])
    const pagination = computed({
      get: () => root.$store.state.forms.admin.list.pagination,
      set: (options) => root.$store.commit('forms/setAdminListPagination', options)
    })
    const showFilters = ref(false)
    const nameFilter = computed({
      get: () => root.$store.state.forms.admin.list.filters.name,
      set: (name) => root.$store.commit('forms/setAdminListFilters', { name })
    })
    const groupFilter = computed({
      get: () => root.$store.state.forms.admin.list.filters.group,
      set: (group) => root.$store.commit('forms/setAdminListFilters', { group })
    })
    const statusFilter = computed({
      get: () => root.$store.state.forms.admin.list.filters.status,
      set: (status) => root.$store.commit('forms/setAdminListFilters', { status })
    })
    const filtersActive = computed(() => {
      return nameFilter.value !== '' || groupFilter.value.length > 0 || statusFilter.value !== ''
    })

    watch([nameFilter, groupFilter, statusFilter], () => loadForms())

    const isLoading = ref(true)
    async function loadForms (options) {
      isLoading.value = true
      if (options) {
        pagination.value = options
      }
      const aggregate = [
        { $match: {
          $or: [
            {
              'access.users': { $elemMatch: {
                pidm: user.value.pidm,
                role: 'Admin'
              } }
            },
            {
              status: { $ne: 'Retired' },
              'access.users': { $elemMatch: {
                pidm: user.value.pidm,
                role: { $in: ['Editor', 'Reviewer'] }
              } }
            }
          ]
        } },
        { $project: {
          _id: 1,
          name: 1,
          shortName: 1,
          group: 1,
          status: 1
        } },
        { $lookup: {
          from: 'Forms-Revision',
          localField: '_id',
          foreignField: 'form',
          as: 'revisions',
          pipeline: [
            {
              $group: {
                _id: '$form',
                latestUpdate: { $max: '$updatedAt' }
              }
            }
          ]
        } },
        { $lookup: {
          from: 'Forms-Submission',
          localField: '_id',
          foreignField: 'form.base',
          as: 'submissions',
          pipeline: [
            {
              $group: {
                _id: '$form.base',
                latestSubmission: { $max: '$submittedDate' },
                count: { $sum: 1 }
              }
            }
          ]
        } },
        { $project: {
          _id: 1,
          name: 1,
          shortName: 1,
          group: 1,
          status: 1,
          revision: { $first: '$revisions' },
          submission: { $first: '$submissions' }
        } },
        { $project: {
          _id: 1,
          name: 1,
          shortName: 1,
          group: 1,
          status: 1,
          latestRevision: '$revision.latestUpdate',
          revDiff: { $dateDiff: {
            startDate: '$revision.latestUpdate',
            endDate: '$$NOW',
            unit: 'day'
          } },
          latestSubmission: '$submission.latestSubmission',
          subDiff: { $dateDiff: {
            startDate: '$submission.latestSubmission',
            endDate: '$$NOW',
            unit: 'day'
          } },
          submissionCount: '$submission.count'
        } }
      ]
      if (nameFilter.value !== '') {
        aggregate[0].$match.name = { $regex: nameFilter.value, $options: 'i' }
      }
      if (groupFilter.value.length > 0) {
        aggregate[0].$match.group = { $in: groupFilter.value }
      }
      if (statusFilter.value === 'Retired') {
        aggregate[0].$match.status = 'Retired'
        delete aggregate[0].$match.$or
        aggregate[0].$match['access.users'] = { $elemMatch: {
          pidm: user.value.pidm,
          role: 'Admin'
        } }
      } else if (statusFilter.value !== '') {
        aggregate[0].$match.status = statusFilter.value
      } else {
        aggregate[0].$match.status = { $ne: 'Retired' }
      }
      const { total, data } = await service.find({ query: { aggregate } })
      formCount.value = total
      forms.value = data
      if (total === 0) {
        if (filtersActive.value) {
          return
        } else if (!isEmployee.value) {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You currently do not have permission to any forms in the Forms Admin system. If you should have access to a form, please contact the employee in charge of that form and make sure they have set up your permissions.' })
          root.$router.push('/')
        } else {
          root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 6000, text: 'You currently do not have permission to any forms in the Forms Admin system. You can create a new form by clicking the + in the upper-right corner, or if you need access to a current form then contact the employee in charge of that form and request access.' })
        }
      }
      isLoading.value = false
    }
    onMounted(() => {
      loadForms()
      // Load the list of groups
      root.$feathers.service('forms/base').find({ query: { $distinct: 'group' } }).then(({ data }) => {
        data.forEach(({ _id }) => groups.value.push({ text: _id, value: _id }))
      })
      if (filtersActive.value && !showFilters.value) showFilters.value = true
    })

    return {
      user,
      isEmployee,
      newForm,
      formValid,
      form,
      name,
      shortName,
      group,
      formGroupErrors,
      loginRequired,
      nameErrors,
      shortNameErrors,
      groups,
      statusOptions,
      copyForm,
      copyFormSearch,
      copyFormItems,
      makeNewForm,
      forms,
      formCount,
      headers,
      pagination,
      showFilters,
      nameFilter,
      groupFilter,
      statusFilter,
      filtersActive,
      isLoading,
      loadForms,
      stringFormatDate
    }
  }
}
</script>
